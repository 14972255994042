<template>
  <div class="teamwork-box">
    <h3>数智协同·云钉  ·  i-DING</h3>
    <h4>企业组织协同与业务融合解决方案 </h4>
    <p>为企业打造一体化、在线化、移动化、专属化的全新数智化办公平台，实现业务平台统一、业务支撑标准统一，帮助企业实现业务融合，减少重复建设。</p><br/>
    <p>解决方案以钉钉开放平台与钉钉专属版为底座，助力企业打造专属、安全、开放的数字化办公平台，重新定义企业自己的数字化工作方式。</p>
    <a :href="onLine" target="_blank">立即咨询了解更多详情</a>
  </div>
</template>
<script>
import { onLine } from "@/data.ts";
export default {
  data() {
    return { onLine }
  }
}
</script>
<style lang="less" scoped>
.teamwork-box{
  padding: 60px 30px;
  background: url(/static/img/synergy-back.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  >h3{
    // text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  >h4{
    font-size: 15px;
    font-weight: 300;
    margin-top: 15px;
    text-align: center;
    padding-bottom: 70px;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      bottom: 30px;
      left: 50%;
      width: 15px;
      height: 2px;
      background-color: #fff;
      margin-right: -7.5px;
    }
  }
  >p{
    font-size: 15px;
    font-weight: 300;
    line-height: 2.5;
    text-align: justify;
  }
  >a{
    text-align: center;
    display: inline-block;
    margin-top: 60px;
    width: 100%;
    border: 0 none;
    background: none;
    appearance: none;
    border-radius: 3px;
    line-height: 42px;
    font-size: 16px;
    font-weight: bold;
    background-color: #E80B10;
    color: #fff;
  }
}
</style>