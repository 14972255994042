<template>
  <div class="synergy-banner">
    <h3>数智协同·云钉   i-DING</h3>
    <p>数字经济时代，组织能力至关重要。<br/>今天企业通过数字化工具、平台实现数字化运营。很快，我们将面临如何建立面向数智化商业世界的全新型组织方式和新型生产关系。</p>
    <a :href="onLine" target="_blank">立即咨询</a>
  </div>
  <PainSpot/>
  <Teamwork/>
  <SynergyComponent/>
  <SynergyValue/>
</template>
<script>
import { onLine } from "@/data.ts";
import PainSpot from '@/components/synergy/PainSpot.vue';
import Teamwork from '@/components/synergy/Teamwork.vue';
import SynergyComponent from '@/components/synergy/Component.vue';
import SynergyValue from '@/components/synergy/Value.vue';
export default {
  components:{
    PainSpot,
    Teamwork,
    SynergyComponent,
    SynergyValue
  },
  data() {
    return { onLine }
  }
}
</script>
<style lang="less" scoped>
.synergy-banner{
  padding: 65px 25px;
  background: url(/static/img/synergy-banner.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  >h3{
    font-size: 20px;
    font-weight: bold;
  }
  >p{
    font-size: 15px;
    font-weight: 300;
    line-height: 2;
    margin-top: 10px;
  }
  >a{
    line-height: 40px;
    text-align: center;
    display: inline-block;
    height: 40px;
    background: #F23D49;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 30px;
    border: 0 none;
    width: 115px;
  }
}
</style>